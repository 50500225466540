import React from "react";
import { Link, graphql } from "gatsby";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import SEO from "../components/seo";

class InfoIndex extends React.Component {
  render() {
    const { data } = this.props;
    const info = data.info.edges;

    const preview = ({ node }) => {
      const title = node.frontmatter.title || node.fields.slug;
      return (
        <article className="px-5 w-full md:w-1/3 mb-8" key={node.fields.slug}>
          <Link to={node.fields.slug}>
            <div className="flex-shrink-0 rounded overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-100 h-full border border-gray-200 cursor-pointer">
              <div className="px-6 py-4 bg-white">
                <div className="font-bold text-xl mb-2">{title}</div>
                <p
                  className="text-gray-700 text-base"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                ></p>
              </div>
            </div>
          </Link>
        </article>
      );
    };

    return (
      <Base>
        <SEO title="Solution de téléexpertise - Toutes les informations" />
        <div className="pb-16">
          <div className="py-16 bg-gray-100">
            <Container>
              <h1 className="text-4xl font-bold">Informations</h1>
            </Container>
          </div>
        </div>
        <Container>
          <div className="block md:flex">
            <div>
              <div className="flex flex-wrap -mx-5 pb-16">
                {info.map((post, index) => {
                  return preview(post, index);
                })}
              </div>
            </div>
          </div>
        </Container>
      </Base>
    );
  }
}

export default InfoIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    info: allMarkdownRemark(
      sort: { fields: [frontmatter___priority], order: ASC }
      filter: { fileAbsolutePath: { regex: "//info/[^/]+/[^/]+.md$/" } }
      limit: 1000
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`;
